/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-undef */
import React from 'react';
import { useLoadScript, GoogleMap, Marker, Polyline } from '@react-google-maps/api';

export default function Map(props) {
  const [center] = React.useState({ lat: 44.076613, lng: -98.362239833 });
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
  });

  const renderMap = () => {
    const options = {
      zoomControlOptions: {
        // @ts-ignore
        position: google.maps.ControlPosition.RIGHT_CENTER, // ,
        // ...otherOptions
      },
    };
    const markerStart: google.maps.Icon = {
      url: 'https://sabre-assets.s3.amazonaws.com/icons/marker-start.png',
      // @ts-ignore
      scaledSize: new google.maps.Size(48, 48),
      anchor: new google.maps.Point(10, 10),
    };
    const markerEnd: google.maps.Icon = {
      url: 'https://sabre-assets.s3.amazonaws.com/icons/marker-end.png',
      // @ts-ignore
      scaledSize: new google.maps.Size(48, 48),
      anchor: new google.maps.Point(10, 10),
    };

    // @ts-ignore
    return (
      <GoogleMap
        options={options}
        mapContainerStyle={{
          height: '70vh',
          width: '100%',
        }}
        zoom={14}
        // Save the user's map click position
        center={props.location?.length ? props.location[props.location.length - 1] : center}
      >
        {props.location && (
          <>
            <Polyline path={props.location} options={{ strokeColor: '#FF0000' }} />
            <Marker title="start" icon={markerStart} position={props.location[0]} />
            <Marker title="end" icon={markerEnd} position={props.location[props.location.length - 1]} />
          </>
        )}
      </GoogleMap>
    );
  };

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  return isLoaded ? renderMap() : <div>Loading map ... </div>;
}
