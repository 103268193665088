import styled from 'styled-components';
import { ReactComponent as SVGLogo } from '../../assets/icons/logo.svg';

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  background: #3a4b55;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const LogoWrapper = styled.div`
  width: 250px;
  height: 90px;
  margin-bottom: 20px;
`;

export const Logo = styled(SVGLogo)`
  width: 100%;
  height: 100%;
`;

export const MapWrapper = styled.div`
  width: 80%;
  height: 80vh;
`;

export const LoadingMap = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Banner = styled.div`
  background-color: green;
  color: white;
  font-size: 1.2rem;
  padding: 20px;
  text-align: center;
  margin: 20px;
`;
