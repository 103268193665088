import React from 'react';
import isEmpty from 'lodash/isEmpty';
import SocketIOClient, { Socket } from 'socket.io-client';
import { Wrapper, LogoWrapper, Logo, MapWrapper, Banner } from './styles';
import Map from './components/Map';
import { Location } from './types';

type TrackingLocationState = {
  location: Location[];
  alert: any;
  userMessage: string | undefined;
  user: any;
};
export class TrackingLocation extends React.Component<any, TrackingLocationState> {
  socket: Socket;
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      userMessage: undefined,
      alert: {},
      location: [],
    };

    const { match } = this.props;
    this.socket = SocketIOClient(process.env.REACT_APP_SERVER, {
      query: {
        alertId: match?.params?.alertId,
      },
      withCredentials: true,
      reconnection: true,
      forceNew: true,
      // jsonp: false,
      upgrade: true,
      rememberUpgrade: true,
      transports: ['websocket'],
    });
  }

  componentDidMount() {
    const { match } = this.props;
    const roomName = `alert-${match?.params?.alertId}`;

    this.socket.on('connect', () => {
      this.socket.emit('room', roomName);
    });

    // Update location getting from server
    this.socket.on('message', (data) => {
      if (data?.location) {
        const newLocation = {
          lat: data.location?.latitude,
          lng: data.location?.longitude,
        };

        this.setState({
          location: [...this.state.location, newLocation],
        });
      }
    });

    this.socket.on('alert-initial', (data) => {
      const newState: any = {};
      newState.location =
        data?.locations?.map((location) => ({ lat: location.coordinates?.[0], lng: location.coordinates?.[1] })) || [];

      if (data?.alert) {
        newState.alert = {
          ...this.state.alert,
          ...data.alert,
        };
      }

      this.setState(newState);
    });

    this.socket.on('alert-end', (data) => {
      const newState: any = {};

      if (data?.alert) {
        newState.alert = {
          ...this.state.alert,
          ...data.alert,
        };
      }

      if (data?.userMessage) {
        newState.userMessage = data.userMessage;
      }

      this.setState(newState);
    });

    this.socket.on('user', (data) => {
      this.setState({
        user: data,
      });
    });
  }

  componentWillUnmount() {
    this.socket?.close?.();
  }

  renderBanner() {
    const { user, userMessage, alert } = this.state;
    if (!isEmpty(alert) && alert.status !== 'alert') {
      const prefix = `${user?.firstName} ${user?.lastName}`;
      const suffix = `in the SABRE Safety App. Contact them here: ${user?.phoneNumber}`;
      const middle = alert.status === 'safe' ? 'has marked themselves as safe' : 'has canceled the alert';
      const message = `${prefix} ${middle} ${suffix}`;

      return <Banner>{userMessage ? userMessage : message}</Banner>;
    }

    return null;
  }

  render() {
    const { location } = this.state;

    return (
      <Wrapper>
        <LogoWrapper>
          <Logo />
        </LogoWrapper>
        {this.renderBanner()}
        <MapWrapper>
          <Map location={location} />
        </MapWrapper>
      </Wrapper>
    );
  }
}
