import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { TrackingLocation } from './pages/TrackingLocation';
import { NoMatch } from './pages/NoMatch';

export default function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/tracking/:alertId" component={TrackingLocation} />
        <Route path="*" component={NoMatch} />
      </Switch>
    </Router>
  );
}
